import React from "react"
import styles from "./team.module.css"
import Img from "gatsby-image"

import { Link } from "gatsby"

export default function Team({ title, description, link, data, image, isMobile }) {
  return (
    <div className={styles.main}>
      <Img className={styles.image} objectFit="contain" fluid={data[image].childImageSharp.fluid} />

      {isMobile === true &&
      <div className={styles.overlayPermanent}>
        <Link to={link}>
          <div className={styles.overlayContentPermanent}>
            <h3 className={styles.titlePermanent}>{title}</h3>
            <h6 className={styles.descriptionPermanent}>{description}</h6>
          </div>
        </Link>
      </div>
      }
      {isMobile === false  &&
      <div className={styles.overlay}>
        <Link to={link}>
          <div className={styles.overlayContent}>
            <h3>{title}</h3>
            <h6>{description}</h6>
          </div>
        </Link>
      </div>
      }
    </div>
  )
}
